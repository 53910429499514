html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

.main {
  font-weight: 900;
  color: #1f1f1f;
  background-color: #f7f7f7;
  /* background-image: linear-gradient(45deg, #5ec1f3, #de33e7); */
  min-height: 100%;
  display: flex;
  padding-left: 60px;
  padding-right: 60px;
  flex-flow: column;
  align-items: center;
}

.content {
  height: 100%;
  font-size: 45px;
  max-width: 750px;
  margin-top: 80px;
}

.title {
  font-size: 70px;
}

.logo {
  max-width: 200px;
  opacity: 0.75;
  height: auto;
  max-height: 100px;
}

.example-image {
  cursor: pointer;
  max-width: 100%;
  height: auto;
  box-shadow: 0px 0px 15px #7a7a7a;
}

.row {
  margin-top: 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.icon-link {
  cursor: pointer;
  margin-right: 40px;
  width: 200px;
  display: flex;
  flex-flow: column;
  align-items: center;
  font-size: 30px;
}

.icon-link > .material-symbols-outlined {
  color: #353535;
  font-size: 100px;
}

.item {
  position: relative;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.45);
}

.item:hover > .caption {
  transition: opacity 600ms;
  opacity: 1;
}

img {
  height: 400px;
}

a {
  color: #353535;
  display: inline;
}

.item {
  display: flex;
  flex-flow: column;
  margin-bottom: 25px;
  overflow: hidden;
  height: 400px;
  width: auto;
  margin-right: 40px;
  /* align-items: center; */
  /* justify-content: center; */
}

.caption {
  opacity: 0;
  transition: opacity 2000ms;
  font-weight: 900;
  color: white;
  font-size: 16px;
  padding: 10px;
  text-shadow: 2px 2px 3px gray;
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow-wrap: break-word;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.45);
}

@media (max-width: 800px) {
  .main {
    padding: 30px;
    flex-flow: column;
  }

  .title {
    font-size: 40px;
  }

  .content {
    font-size: 25px;
    margin-top: 30px;
  }

  .row {
    justify-content: center;
  }

  .icon-link > .material-symbols-outlined {
    font-size: 100px;
    margin-top: 20px;
  }

  .item {
    display: flex;
    flex-flow: column;
    margin-bottom: 25px;
    overflow: hidden;
    height: auto;
    width: 100%;
    margin-right: 40px;
  }
  img {
    height: auto;
    width: 100%;
  }
}
